<template>
  <div class="emergency-webview">
    <span class="emergency-tabs__head">Webview</span>
    <a-input
      v-model="webviewValue"
      placeholder="https://"
      class="webview-input mt-2"
      @change="changeWebviewValue"
    />
  </div>
</template>

<script>

export default {
  props: ['webview'],
  data() {
    return {
      webviewValue: '',
    }
  },
  methods: {
    changeWebviewValue() {
      this.$emit('changeWebviewValue', this.webviewValue)
    },
  },
  mounted() {
    this.webviewValue = this.webview
  },
}
</script>

<style lang="scss">
.emergency-webview {
  display: flex;
  flex-direction: column;
  & .webview-input {
    max-width: 650px;
  }
}
</style>
